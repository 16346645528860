import { Fragment, React, useCallback, useEffect } from "react";
import Header from "../../Components/home-components/Header/Header";
import KYCSearch from "../../Components/home-components/KYCSearch/KYCSearch";
import KYCInfoCard from "../../Components/home-components/KYC-Info-Card/KYCInfoCard";
import BottomKYCCard from "../../Components/home-components/BottomKYCCard/BottomKYCCard";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getCustomerAllKyc } from "../../services/customer-service";
import { kycRefId, kycRefIdAddNewKyc } from "../Redux/kycDetailsSlice";
import { toggleKycTypeTab, toggleTabs } from "../Redux/slice";
import { updateUserData, userLocation } from "../Redux/userData";
import MsHome from "../../Components/Maruti-Suzuki-components/MsHome";
import { decrypt } from "../../utils/encryption";
import { TabPill } from "../../Components/kyc-components/Tab";
import { getCorporateCustomerAllKyc } from "../../services/corporate-kycSearch-service";
import {
  clearState,
  coAddNewKycIdReducer,
  cokycIdReducer,
} from "../Redux/corporateSlice";
const HomeScreen = () => {
  const userDetails = useSelector((state) => state.userDetails.userData);
  const kycTypeTab = useSelector((state) => state.Tabs.kycTypeTab);
  const [existingRecord, setExistingRecord] = useState(null);
  const [limit,setLimit]=useState(5)
  const [totalUserKyc, setTotalUserKyc] = useState(0);
  const [coTotalUserKyc, setCoTotalUserKyc] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const fetchExistingRecords = () => {
    getCustomerAllKyc()
      .then((data) => {
        let dnc = decrypt(data?.data);
        if(JSON.parse(dnc)?.limit )setLimit(JSON.parse(dnc)?.limit)
        if (data?.data) {
          setTotalUserKyc(JSON.parse(dnc)?.kyc?.length || 0);
          return setExistingRecord(JSON.parse(dnc));
        }
      })
      .catch((err) => console.log(err));
  };

  const corporateFetchExistingRecords = () => {
    getCorporateCustomerAllKyc().then((data) => {
      let dnc = decrypt(data?.data);

      let corpKyc = { kyc: JSON.parse(dnc)?.kyc_corporate };
 
        if(JSON.parse(dnc).limit )setLimit(JSON.parse(dnc).limit)
      if (data?.data) {
        setCoTotalUserKyc(corpKyc?.kyc?.length || 0);
        return setExistingRecord(corpKyc);
      }
    });
  };
  const searchResultHandler = useCallback((data) => {
    setExistingRecord(data);
  }, []);

  const searchTermHandler = useCallback((data) => {
    if (!data) return fetchExistingRecords();
  }, []);
  const onClickResolveHandler = (data) => {
    dispatch(kycRefId(data)); //kycrefid
    navigate(kycTypeTab === "Individual" ? "/kyc#Resolve" : "/corporatePage");
    dispatch(toggleTabs(kycTypeTab === "Individual" ? "otherDocs" : ""));
    dispatch(
      kycTypeTab === "Individual" ? kycRefId(data) : cokycIdReducer(data)
    ); //kycrefid
    dispatch(
      updateUserData({
        kycTypeSelected:
          kycTypeTab === "Individual" ? "Individual" : "Corporate",
      })
    );
  };
  const onClickProceedhandler = (data) => {
    navigate("/Success", {
      state: { kyc_ref_id: data },
    });
  };
  const fetchUserLocation = useCallback(() => {
    navigator.geolocation.getCurrentPosition(
      function (position) {
        const classToObject = (theClass) => {
          const originalClass = theClass || {};
          const keys = Object.getOwnPropertyNames(
            Object.getPrototypeOf(originalClass)
          );
          return keys.reduce((classAsObj, key) => {
            classAsObj[key] = originalClass[key];
            return classAsObj;
          }, {});
        };
        dispatch(
          userLocation({
            location: JSON.stringify(classToObject(position.coords)),
            timeStamp: position.timestamp,
          })
        );
      },
      function (error) {
        console.error("Error Code = " + error.code + " - " + error.message);
      }
    );
  }, [dispatch]);
  const onClickAddNewKYCHandler = (kycType) => {
    navigate(kycType === "Individual" ? "/kyc" : "/corporatePage");
    dispatch(kycType === "Individual" ? kycRefId(null) : cokycIdReducer(null));
    dispatch(updateUserData({ kycTypeSelected: kycType }));
  };
  const onClickEditHandler = (data) => {
    //edit
    navigate(kycTypeTab === "Individual" ? "/kyc" : "/corporatePage");
    dispatch(
      kycTypeTab === "Individual" ? kycRefId(data) : cokycIdReducer(data)
    ); //kycrefid
    dispatch(
      updateUserData({
        kycTypeSelected:
          kycTypeTab === "Individual" ? "Individual" : "Corporate",
      })
    );
  };
  const onClickUseAnotherMethod = (data) => {
    // use another method
    navigate(kycTypeTab === "Individual" ? "/kyc" : "/corporatePage");
    dispatch(
      kycTypeTab === "Individual" ? kycRefId(data) : cokycIdReducer(data)
    ); //kycrefid
    dispatch(
      updateUserData({
        kycTypeSelected:
          kycTypeTab === "Individual" ? "Individual" : "Corporate",
      })
    );
  };
  useEffect(() => {
    fetchUserLocation();
  }, [dispatch, fetchUserLocation]);
  useEffect(() => {
    if (userDetails.phoneNumber) {
      fetchExistingRecords();
      return;
    }
  }, [userDetails.phoneNumber]);
  useEffect(() => {
    dispatch(kycRefIdAddNewKyc(""));
    dispatch(kycRefId(""));
    dispatch(toggleKycTypeTab("Individual"));
    fetchExistingRecords();
    dispatch(clearState());
  }, []);

  return (
    <div className="h-full lato-family relative">
      <MsHome>
        <div className="md:mt-4 md:pl-3 md:pr-3 md:h-full md:relative">
          <div className="p-3 rounded-lg md:relative w-[100%]  md:h-[100%]  overflow-scroll">
            <KYCSearch
              searchTermHandler={searchTermHandler}
              searchResultHandler={searchResultHandler}
            />
            <div className="md:py-3 py-2 relative flex justify-between">
              <div className="left-wrapper">
                <p className="text-sm mt-4 font-medium mb-2">
                  Existing Records
                </p>
              </div>

              <div className="right-wrapper h-[32px] flex gap-2 mt-2">
                <TabPill
                  label="Individual"
                  onClick={() => {
                    dispatch(toggleKycTypeTab("Individual"));
                    fetchExistingRecords();
                  }}
                  isActive={kycTypeTab === "Individual"}
                  id="individual-kyc"
                  style={{ padding: 1, paddingLeftRight: 2 }}
                  isInfoIcon={false}
                />
                <TabPill
                  label="Corporate"
                  onClick={() => {
                    dispatch(toggleKycTypeTab("Corporate"));
                    setExistingRecord(null);
                    corporateFetchExistingRecords();
                  }}
                  isActive={kycTypeTab === "Corporate"}
                  id="corporate-kyc"
                  style={{ padding: 1, paddingLeftRight: 2 }}
                  isInfoIcon={false}
                />
              </div>
            </div>
            <div className="flex flex-col space-y-4 md:space-y-0 z-0   h-auto last:mb-[20vh] md:last:mb-[7vh] md:grid md:grid-cols-2 md:gap-x-2 md:gap-y-3 md:w-full">
              {existingRecord?.kyc?.length < 1 && (
                <p className=" text-gray-500 text-sm">
                  {totalUserKyc <= 4 ? (
                    <p>
                      No Records found, kindly click <b>Add New KYC</b> to start
                      your KYC submission
                    </p>
                  ) : (
                    "No Records found"
                  )}
                </p>
              )}
              {kycTypeTab === "Corporate" && coTotalUserKyc === 0 && (
                <p className="text-gray-500 text-sm">
                  Kindly click <b>Add Corporate KYC</b> to start your KYC
                  submission
                </p>
              )}
              {existingRecord?.kyc?.map((kycRecords, index, arr) => {
                return (
                  <Fragment key={index + kycRecords?.kycRefId}>
                    <KYCInfoCard
                      data={kycRecords}
                      onClickUseAnotherMethod={onClickUseAnotherMethod}
                      onClickResolve={onClickResolveHandler}
                      onClickProceed={onClickProceedhandler}
                      onClickEdit={onClickEditHandler}
                    />
                  </Fragment>
                );
              })}
            </div>
          </div>
          {kycTypeTab === "Individual" && totalUserKyc <= limit && 
          <div className=" md:flex md:justify-center">
            <BottomKYCCard
              onClick={onClickAddNewKYCHandler}
              // showAddNewKyc={totalUserKyc <= limit || 5}
              showAddNewKyc={ totalUserKyc < limit}
              label="Individual"
            />
          </div>
}
{   kycTypeTab === "Corporate" &&
        <div className=" md:flex md:justify-center">
          <BottomKYCCard
            onClick={onClickAddNewKYCHandler}
            showAddNewKyc
            // showAddNewKyc={coTotalUserKyc < limit}
            label="Corporate"
          />
        </div>
}
        </div>
        <div className=" visible text-center md:mt-2 ml:auto mr:auto w-[90%]">
          <p>
            <span className="opacity-50">Powered by</span> &nbsp;
            <span className="drop-shadow-md bg-white pl-1 pr-1 pt-0.5 pb-0.5 rounded-sm font-bold">
              Ctrl
            </span>
            &nbsp;
            <span className="drop-shadow-md bg-red-500 pl-1 pr-1 pt-0.5 pb-0.5 primaryH rounded-sm text-white font-bold">
              N
            </span>
          </p>
        </div>

      </MsHome>
    </div>
  );
};

export default HomeScreen;
